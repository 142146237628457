/* Custom scrollbar styles */

.custom_knob {
  .p-knob-text {
    font-size: 12px !important;
  }
}
.custom-progress-antd {
  .ant-progress-text {
    display: none;
  }
}
.custom-datatable ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: #000;
}

.custom-datatable ::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 5px;
}

.custom-datatable ::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Add this to maintain a consistent black scrollbar */
.custom-datatable ::-webkit-scrollbar-thumb:vertical {
  background-color: #888 !important; /* Use !important to override default behavior */
}

.custom-datatable ::-webkit-scrollbar-thumb:horizontal {
  background-color: #888 !important; /* Use !important to override default behavior */
}

// report select
.custom-scrollable-select ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: #000;
}

.custom-scrollable-select ::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 5px;
}

.custom-scrollable-select ::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Add this to maintain a consistent black scrollbar */
.custom-scrollable-select ::-webkit-scrollbar-thumb:vertical {
  background-color: #888 !important; /* Use !important to override default behavior */
}

.custom-scrollable-select ::-webkit-scrollbar-thumb:horizontal {
  background-color: #888 !important; /* Use !important to override default behavior */
}

.frozen-column {
  border-left: 15px solid #ccc; /* Màu và độ dày đường viền có thể thay đổi */
}
.custom-datatable {
  font-family: 'Inter', sans-serif;
}
.custom-datatable div table {
  border-collapse: initial !important ;
  padding: 0 !important;
}
.p-column-filter-clear-button {
  display: none !important;
}
.table-container {
  background-color: white; /* Đặt màu nền trắng */
  overflow: auto; /* Tạo thanh cuộn nếu bảng vượt quá kích thước */
  max-height: 600px; /* Đặt kích thước tối đa */
}
.p-datatable-wrapper {
  background-color: white; /* Đặt màu nền trắng */
  overflow: auto; /* Tạo thanh cuộn nếu bảng vượt quá kích thước */
  max-height: 750px; /* Đặt kích thước tối đa */
  height: 750px;
}
#danhSachLoiHoSoThanhToan {
  .p-datatable-wrapper {
    height: initial !important;
  }
}
.no-height-datatable-wrapper {
  .p-datatable-wrapper {
    max-height: initial !important; /* Đặt kích thước tối đa */
    height: initial !important;
  }
}
// .custom-datatable {
//   width: 99.9%;
// }
#material--table {
  .MuiPaper-root ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .MuiPaper-root ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
  .MuiPaper-root ::-webkit-scrollbar-thumb:vertical {
    background-color: #888 !important; /* Use !important to override default behavior */
  }
  .MuiPaper-root ::-webkit-scrollbar-thumb:horizontal {
    background-color: #888 !important; /* Use !important to override default behavior */
  }
}
.margin_datepicker {
  margin-top: 19px;
}

.pagination_hopdong {
  // position: absolute;
  // bottom: 0px;
  position: absolute;
  bottom: 0;
  left: 22em;
  right: 0;
}
.no-border-input-buoc2 {
  border: none !important;
  outline: none !important;
  background-color: transparent !important;
  opacity: 1 !important;
  .react-datepicker-wrapper {
    border: none !important;
    outline: none !important;
    background-color: transparent !important;
  }
  .p-disabled {
    border: none !important;
    outline: none !important;
    background-color: transparent !important;
  }
  .p-disabled,
  .p-component:disabled {
    opacity: initial !important;
  }
}
@media screen and (max-height: 645px) {
  .pagination_hopdong {
    // position: absolute;
    // bottom: 0px;
    position: relative;
    left: 0em;
  }
}

.select_hopdong {
  font-weight: 400;
}
#datepickercustom {
  .react-datepicker-wrapper {
    width: 100% !important;
  }
}
// .p-component-overlay{
//   background-color: white !important
// }
/* Cho Firefox */
#mucphidongbaohiem {
  .p-inputtext {
    text-align: center;
  }
}

.p-datatable .p-datatable-loading-overlay {
  background-color: transparent !important;
}
#my-superset-container {
  iframe {
    background-color: gray;
    #document {
      background-color: gray;

      .css-zz6fcy {
        background-color: red;
        .css-z133oh {
          display: none !important;
        }
      }
    }
  }
  .dashboard-filters {
    /* CSS properties */
    display: none !important;
  }
}
#input_tien_lichsukcb {
  .p-inputtext {
    background: none;
    border: none;
    color: #495057;
    opacity: initial !important;
  }
}

#datepicker {
  .react-datepicker-popper {
    z-index: 20 !important;
    position: absolute !important;
  }
}

// upload file b4 hop dong
.custom-file-upload {
  border: none !important;
}
.custom-file-upload input[type='file'] {
  display: none;
}

.custom-file-upload label {
  // background-color: #3498db;
  color: black;
  // padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.custom-file-upload label:hover {
  // background-color: #2980b9;
}
.filename {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.basic-multi-select__menu-list::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.basic-multi-select__menu-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.basic-multi-select__menu-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.basic-multi-select__menu-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#table_denghithanhtoan {
  .p-datatable-wrapper {
    max-height: initial !important ;
    height: initial !important;
  }
  .scroll_select_table_dntt::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .scroll_select_table_dntt::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .scroll_select_table_dntt::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  .scroll_select_table_dntt::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
#datatablePheDuyet,
#tableChiTietMaDoiSoat {
  .p-datatable-wrapper {
    max-height: initial !important ;
    height: initial !important;
  }
}
//  hiện ***** cho thanh toán
.hidden-content {
  width: 100%; /* Chiều rộng tối đa của phần tử */
  white-space: nowrap; /* Không xuống dòng */
  overflow: hidden; /* Ẩn phần nội dung tràn ra ngoài */
  text-overflow: ellipsis; /* Hiển thị dấu ba chấm (...) */
}

.padding_btn_common_hopdong {
  padding: calc(0.775rem + 1px) calc(1.5rem + 1px) !important;
}
#inputnumber {
  .valid_inputNumber_loiHanMuc {
    input,
    .p-disabled {
      border: 1px solid red !important;
      font-weight: initial !important;
      color: #495057 !important;
    }
  }
}

#dataTable {
  .error-border {
    border: 2px solid red !important; /* !important để đảm bảo rằng lớp này có ưu tiên cao hơn các quy tắc CSS khác */
    border-collapse: '' !important ;
  }
  .ant-select-arrow {
    display: none;
  }
  .ant-select-open {
    border: none !important;
  }
  .ant-select-selector {
    border-bottom: hidden !important;
    border-top-style: hidden !important;
    border-left-style: hidden !important;
    border-right-style: hidden !important;
    box-shadow: none !important;
    color: #495057 !important;
  }
  :where(.css-dev-only-do-not-override-1x0dypw).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
    .ant-select-selector {
    // border-color: transparent !important;
    // border: none !important;
  }
  :where(.css-dev-only-do-not-override-1x0dypw).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
    .ant-select-selector {
    // border-color: transparent !important;
    // border: none !important;
  }
  .ant-select-focused:where(.css-dev-only-do-not-override-1x0dypw).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
    .ant-select-selector {
    // border-color: transparent !important;
    // border: none !important;
  }
  :where(.css-dev-only-do-not-override-1x0dypw).ant-select-outlined:not(.ant-select-customize-input)
    .ant-select-selector {
    // border: none !important;
    // border-color: transparent !important;
  }
  :where(.css-dev-only-do-not-override-1x0dypw).ant-select-single.ant-select-show-arrow
    .ant-select-selection-item,
  :where(.css-dev-only-do-not-override-1x0dypw).ant-select-single.ant-select-show-arrow
    .ant-select-selection-search,
  :where(.css-dev-only-do-not-override-1x0dypw).ant-select-single.ant-select-show-arrow
    .ant-select-selection-placeholder {
    padding-inline-end: '0px' !important;
  }
}

.w-100-textarea {
  width: 100% !important;
}

.creatable-select {
  .css-1fdsijx-ValueContainer {
    cursor: pointer !important;
  }
}

.sticky-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px 6em 10px 0;
}

.sticky-containers {
  position: sticky;
  width: 100%;
  bottom: 0;
  z-index: 1000;
  background-color: white;
}
#pdf-preview-container {
  position: relative;
}

#pdf-preview-container object {
  width: 100%;
  height: 600px;
}

#pdf-preview-container .pdf-toolbar {
  display: none; /* Thử ẩn thanh công cụ nếu trình duyệt hỗ trợ */
}

.p-column-filter-operator {
  display: none !important;
}

.custom-multiselect .p-multiselect-panel {
  max-width: 200px; /* Giới hạn chiều rộng của popup */
  width: 100%; /* Đảm bảo rằng chiều rộng không vượt quá giới hạn */
}

.custom-multiselect .p-multiselect-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.p-column-filter-constraint {
  height: 300px;
  width: 500px; /* Chiều cao của vùng chứa */
  overflow-y: auto; /* Hiển thị thanh cuộn dọc khi nội dung vượt quá chiều cao */
  overflow-x: auto; /* Ẩn thanh cuộn ngang */
}

/* Tùy chỉnh thanh cuộn dọc */
.p-column-filter-constraint::-webkit-scrollbar {
  width: 12px; /* Đặt độ rộng cho thanh cuộn dọc */
}

.p-column-filter-constraint::-webkit-scrollbar-thumb {
  background: #888; /* Màu của thanh cuộn dọc */
  border-radius: 8px; /* Bo góc của thanh cuộn dọc */
}

.p-column-filter-constraint::-webkit-scrollbar-track {
  background: #f1f1f1; /* Màu nền của thanh cuộn dọc */
}

/* Đảm bảo không có thanh cuộn ngang */
.p-column-filter-constraint::-webkit-scrollbar-horizontal {
  display: none; /* Ẩn thanh cuộn ngang */
}
#chitietdoisoat {
  .MuiStepIcon-root.Mui-completed,
  .MuiStepIcon-root.Mui-active {
    color: #219342 !important;
    border: 2px !important;
    width: auto;
    height: 30px !important;
  }
  .Mui-disabled .MuiStepIcon-root {
    width: auto;
    height: 30px !important;
  }
  .css-z7uhs0-MuiStepConnector-line {
    // border-color: #219342 !important;
    // border-top-width: '0px' !important;
  }
  .MuiStepConnector-root.Mui-disabled {
    .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
      border-color: #dbdfe5 !important;
      border-style: dashed !important;
      border-top-width: 1px !important;
    }
  }
  .MuiStepLabel-label {
    font-weight: bold;
    font-size: 14px;
  }
  .MuiStepConnector-root.Mui-active,
  .MuiStepConnector-root.Mui-completed {
    .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
      border-color: #219342 !important;
      border-top-width: 1px !important;
    }
  }
}

// .custom-filter-popup .p-column-filter-buttonbar {
// display: none !important;
// }
.tableLichSu {
  .active_icon_filter {
    .p-column-filter-menu-button {
      background: #eef2ff;
      color: #4338ca;
    }
  }
}

.width_thaotac {
  min-width: 50px;
  max-width: 50px;
}
@media (max-width: 1500px) {
  /* Styles for devices with width less than or equal to 1366px */
  .width_thaotac {
    min-width: 70px;
    max-width: 70px;
  }
}
// css cho màn chi tiết 3 hồ sơ
.maBenhPhuHoSo {
  padding-top: 10px !important;
}
.selectCommonDiv,
.inputNumberTyleThuongTat {
  padding-top: 6px !important;
}
.inputLyDoDenKham {
  padding-top: 14px !important;
}
.stepTrangThaiDoiSoat {
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 10px;
}
@media (max-width: 1431px) {
  .stepTrangThaiDoiSoat {
    width: 100%;
    font-size: '12px';
  }
}
.soLuongQLBH {
  display: flex;
  width: 94%;
  align-items: center;
  gap: 10;
}
.hideCheckbox table thead tr:nth-of-type(1) .p-checkbox-box {
  display: none;
}
.bg-indigo-500 {
  background-color: #6366f1 !important;
}
.bg-purple-500 {
  background-color: #a855f7 !important;
}
.bg-teal-500 {
  background-color: #14b8a6 !important;
}
.p-organizationchart .p-organizationchart-node-content {
  border: 2px solid #658a52 !important;
  border-radius: 5% !important;
  width: 11vw !important;
}
.p-organizationchart-line-down {
  height: 32px !important;
  border: 2px dotted !important;
}
.select-fullwidth {
  width: 100% !important;
}

.scroll_orgchart {
  width: 100%; /* Độ rộng của container */
  height: 500px; /* Chiều cao của container, bạn có thể chỉnh sửa */
  overflow-y: scroll; /* Kích hoạt thanh cuộn dọc */
  overflow-x: hidden; /* Ẩn thanh cuộn ngang (nếu không muốn cuộn ngang) */
  scrollbar-width: thin; /* Độ rộng thanh cuộn cho Firefox */
  scrollbar-color: #888 #f1f1f1; /* Màu sắc thanh cuộn và nền của nó */
}

/* Thiết lập thanh cuộn cho Chrome, Edge và Safari */
.scroll_orgchart ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: #000;
}

.scroll_orgchart ::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 5px;
}

.scroll_orgchart ::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Add this to maintain a consistent black scrollbar */
.scroll_orgchart ::-webkit-scrollbar-thumb:vertical {
  background-color: #888 !important; /* Use !important to override default behavior */
}

.scroll_orgchart ::-webkit-scrollbar-thumb:horizontal {
  background-color: #888 !important; /* Use !important to override default behavior */
}

#chartQuyBT {
  td {
    border: 1px solid black !important ;
  }
  th {
    padding: 5px !important;
  }
  table {
    border-collapse: collapse !important;
  }
  .custom-datatable div table {
    border-collapse: collapse !important;
    /* border-collapse: initial !important; */
  }
  .p-datatable-wrapper {
    overflow: auto;
    max-height: initial !important;
    height: initial !important;
  }
  .custom-datatable ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: #000;
  }

  .custom-datatable ::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 5px;
  }

  .custom-datatable ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  /* Add this to maintain a consistent black scrollbar */
  .custom-datatable ::-webkit-scrollbar-thumb:vertical {
    background-color: #888 !important; /* Use !important to override default behavior */
  }

  .custom-datatable ::-webkit-scrollbar-thumb:horizontal {
    background-color: #888 !important; /* Use !important to override default behavior */
  }
}
#popupQuyenLoiChaCon {
  .p-datatable-wrapper {
    height: initial !important;
  }
}
.input_songayanhan_tabledotdongphi {
  input {
    width: 80%;
  }
}
// css cho màn chi tiết 3 hồ sơ
.maBenhPhuHoSo {
  padding-top: 10px !important;
}
.selectCommonDiv,
.inputNumberTyleThuongTat {
  padding-top: 6px !important;
}
.inputLyDoDenKham {
  padding-top: 14px !important;
}
.input_songayanhan_tabledotdongphi {
  input {
    width: 80%;
  }
}
#tableKTTBH {
  //  .p-column-header-content .p-checkbox {
  //     display: none !important;
  //  }
}
.modal_cauhinhduyet_hsbt {
  @media (min-width: 1200px) {
    .modal-xl {
      --bs-modal-width: 80vw !important;
    }
  }
  .modal-content {
    width: 80vw !important;
  }
}
#tableCauHinhDuyet {
  .MuiTableCell-root[data-index='2'] {
    display: none !important;
  }
  .MuiCollapse-root,
  .MuiButtonBase-root {
    display: none !important;
  }
  .MuiBox-root {
    display: none;
  }
  .MuiTableHead-root {
    .MuiBox-root {
      display: block;
    }
  }
  .p-column-header-content .p-checkbox {
    display: none !important;
  }
  // .p-column-header-content .p-checkbox {
  //   display: none !important;
  // }
}
#tableQuanLyDanhMucThuoc {
  tr.p-row-odd {
    background-color: #f2f4f7 !important;
  }
  .p-resizable-column {
    background-color: #eaefec !important;
  }
  .p-column-filter-menu {
    display: none !important;
  }
}
#tableQuanLyDanhMucThuocPopup {
  tr.p-row-odd {
    background-color: #f2f4f7 !important;
  }
  .p-resizable-column {
    background-color: #eaefec !important;
  }
  .p-column-filter-menu {
    display: none !important;
  }
  .p-datatable-wrapper {
    max-height: initial !important;
    height: initial !important;
  }
  .p-column-header-content .p-checkbox {
    display: none !important;
  }
}
#input_number_popupcalculator {
  .p-inputnumber {
    display: flex;
    justify-content: right;
  }
  input {
    border-bottom: 1px solid lightslategray;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    box-shadow: none;
    text-align: right;
    max-width: 58%;
    border-radius: 5px;
  }
}
#input_number_popupcalculator_percent {
  .p-inputnumber {
    display: flex;
    justify-content: right;
  }
  input {
    border-bottom: 1px solid lightslategray;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    box-shadow: none;
    text-align: right;
    max-width: 93%;
    border-radius: 5px;
  }
  .inputnumber_bangtinh_quyenloi {
    input {
      text-align: right !important;
    }
  }
}
#dropdown_dongchitra {
  .css-13cymwt-control {
    border: none;
  }
  .css-hlgwow {
    display: none;
  }
  .css-1u9des2-indicatorSeparator {
    display: none;
  }
}
.inputnumber_bangtinh_quyenloi {
  input {
    text-align: right;
  }
}

#modalBangTinh {
  .modal-content {
    height: 83vh;
    overflow-y: auto;
  }
}
#inputnumber,
#dataTableTGCCB {
  .marginInputNumberCustom {
    margin-left: 10px !important;
  }
}
.marginInputNumberCustom {
  margin-left: 10px !important;
  .p-column-header-content .p-checkbox {
    display: none !important;
  }
}
#subtable-cau-hinh-duyet {
  .p-datatable-wrapper {
    height: initial !important;
    max-height: initial !important;
  }
}

.width_thaotac {
  min-width: 50px;
  max-width: 50px;
}
@media (max-width: 1500px) {
  /* Styles for devices with width less than or equal to 1366px */
  .width_thaotac {
    min-width: 70px;
    max-width: 70px;
  }
}
#tableLogNDBH {
  .p-datatable-wrapper {
    .p-datatable-row-expansion td {
      padding: 0 !important; /* Reset padding in the expanded row to avoid excessive spacing */
    }

    .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
      padding: 10px !important; /* Ensure consistent padding in the main table cells */
    }
  }

  .subTableLogNDBH {
    background-color: #e9edec;
    padding: 15px 0px 15px 35px !important; /* Ensure padding for the expanded table is consistent */

    .p-datatable-wrapper {
      max-height: unset !important; /* Avoid overriding height, let it adjust naturally */
      height: unset !important; /* Let content determine height */
    }
  }
}

.alertDuyetDanhSachHsBlt {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 24px;
  position: absolute;
  bottom: 2%;
  border-radius: 8px;
  background-color: #366ae2;
  left: 50%;
  z-index: 1000;
}

.react-select-group-inputext {
  .css-ag0koa-control,
  .css-ydp40b-control {
    margin-right: initial !important;
    border-right: none !important;
    border-top-right-radius: initial !important;
    border-bottom-right-radius: initial !important;
  }
  .css-1u9des2-indicatorSeparator{
    display:  none !important;
  }
}
